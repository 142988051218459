<template>
  <ProductTitledBlock
    v-if="showPropertiesBlock"
    :title="model.title || $t('shared.catalog.product_details.technical_specs_block_title')"
    icon="adjustments"
  >
    <VcCollapsibleContent max-height="12.5rem">
      <div class="space-y-4">
        <!-- Vendor -->
        <VcProperty v-if="showVendor" :label="$t('shared.catalog.product_details.vendor_label')" class="text-base">
          <Vendor :vendor="product.vendor!" with-rating />
        </VcProperty>
        <!-- OPUS -->
        <VcProperty v-for="property in properties" :key="property.name" :label="property.label!" class="text-base">
          <template v-if="!isFileLink(property.value as string)">{{ property.value }}</template>
          <template v-else>
            <a
              :href="property.value as string"
              target="_blank"
              class="cursor-pointer text-[--link-color] hover:text-[--link-hover-color]"
              @click="downloadFile(property.name, property.value as string)"
              >{{ $t("shared.catalog.product_details.view_file") }}
            </a>
          </template>
        </VcProperty>
        <!-- !OPUS -->
      </div>
    </VcCollapsibleContent>
    <!-- Properties -->
  </ProductTitledBlock>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { PropertyType } from "@/core/api/graphql/types";
import { configInjectionKey } from "@/core/injection-keys";
import { getPropertiesGroupedByName } from "@/core/utilities";
import { ProductTitledBlock, Vendor } from "@/shared/catalog";
import { generateLinkAndDownloadFile } from "@/shared/opus";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

const props = defineProps<IProps>();

const config = inject(configInjectionKey, {});

const properties = computed(() => {
  const groupedProperties = getPropertiesGroupedByName(props.product.properties ?? [], PropertyType.Product);
  return Object.values(groupedProperties).filter((property) => property.value);
});

async function downloadFile(name: string, url: string) {
  if (name && url) {
    await generateLinkAndDownloadFile(name, url);
  }
}

function isFileLink(value?: string): boolean {
  if (value) {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const filePattern = /\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|ppt|pptx)$/i;
    return urlPattern.test(value) || filePattern.test(value);
  } else {
    return false;
  }
}

const showVendor = computed(() => config.vendor_enabled && !props.product.hasVariations && props.product.vendor);
const showPropertiesBlock = computed(() => !props.model.hidden && (properties.value.length || showVendor.value));
</script>
