<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <VcModal
    :title="$t('shared.opus.quick_connect.supplier_search_dialog.title')"
    modal-width="max-w-[1300px]"
    class="md:height:580px;"
    is-mobile-fullscreen
    divide-actions-panel
    is-scrollable-on-desktop
  >
    <div class="flex h-full flex-col">
      <!-- Search and sort -->
      <div class="flex flex-col items-center gap-x-6 border-b px-4 pb-3.5 pt-3 shadow-md md:flex-row">
        <div class="flex w-full grow flex-col gap-y-1.5 pb-3 md:pb-0">
          <label
            v-t="'shared.opus.quick_connect.supplier_search_dialog.filters.search_label'"
            class="block text-sm font-semibold"
          />
          <OpSupplierSearchBar class="h-9 w-full" :tab-accessability-index="1" />
        </div>
        <div class="flex w-full flex-row md:w-48">
          <div v-if="isMobile && displayedCategories" class="mr-3 flex w-4/5 flex-col gap-y-1.5 md:mr-0 md:w-48">
            <label
              v-t="'shared.opus.quick_connect.supplier_search_dialog.filters.categories_label'"
              class="block text-sm font-semibold md:hidden"
            />
            <VcSelect
              v-model="currentSelectedCategory[0]"
              text-field="label"
              value-field="term"
              :disabled="loading"
              :items="displayedCategories"
              class="h-9"
              size="sm"
              @change="(category) => onCategorySelect(category)"
            >
              <template #item="{ item }">
                <div class="flex w-full items-center justify-between">
                  <span class="truncate">{{ item.label }}</span>
                  <VcBadge
                    variant="outline"
                    color="secondary"
                    class="ml-0.5 items-center text-xxs font-bold"
                    size="md"
                    rounded
                  >
                    {{ item.count }}
                  </VcBadge>
                </div>
              </template>
            </VcSelect>
          </div>
          <div class="flex w-auto flex-col gap-y-1.5">
            <label
              v-t="'shared.opus.quick_connect.supplier_search_dialog.filters.sort_label'"
              class="block text-sm font-semibold"
            />
            <VcSelect
              v-model="sortSuppliersBy"
              text-field="name"
              value-field="id"
              :disabled="loading"
              :items="SUPPLIER_SEARCH_SORTING_LIST"
              class="h-9"
              size="sm"
              @change="(sortValue) => onSortSelect(sortValue)"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-1 overflow-hidden">
        <!-- Categories -->
        <div
          v-if="!isMobile && displayedCategories"
          class="max-h-[calc(100vh-100px)] w-[286px] overflow-y-auto border-r py-2 pl-1.5 pr-2 lg:max-h-[calc(100vh-300px)]"
        >
          <div
            v-for="(category, index) in displayedCategories"
            :key="index"
            class="flex items-center space-x-1 overflow-hidden text-ellipsis px-3 py-1 text-xs"
          >
            <a
              role="button"
              tabindex="0"
              class="hover:text-black line-clamp-2 flex w-full cursor-pointer gap-x-1 gap-y-1.5 px-1.5 py-1 hover:rounded-md hover:bg-secondary-50"
              :class="{ 'rounded-md bg-secondary-100': isCategorySelected(category.term) }"
              @keydown.enter="onCategorySelect(category.term)"
              @click="onCategorySelect(category.term)"
            >
              <span class="grow overflow-hidden text-ellipsis text-nowrap text-xs">{{ category.label }}</span>
              <VcBadge variant="outline" color="secondary" class="items-center text-xxs font-bold" size="md" rounded>
                {{ category.count }}
              </VcBadge>
            </a>
          </div>
        </div>

        <!-- Suppliers -->
        <div
          class="max-h-[calc(100vh-100px)] flex-1 overflow-y-auto pb-4 pl-1.5 pr-4 pt-1 lg:max-h-[calc(100vh-300px)]"
        >
          <VcLoaderOverlay v-if="loading" />
          <div v-if="suppliers.length" class="grid grid-cols-1 md:grid-cols-2 md:gap-x-3">
            <div
              v-for="(supplier, index) in suppliers"
              :key="index"
              class="flex flex-row items-start justify-between pb-2.5 pl-4 pr-3 pt-0.5 hover:bg-secondary-50"
              :class="{ 'bg-primary-50': isSupplierSelected(supplier.id) }"
            >
              <VcCheckbox
                class="flex flex-col items-start truncate"
                :model-value="isSupplierSelected(supplier.id)"
                :disabled="isNotEditableItem(supplier.id)"
                @update:model-value="onSupplierSelect(supplier)"
              >
                <div class="mt-1.5">
                  <span class="truncate text-sm">{{ supplier.name }}</span>
                  <p class="mt-0.5 text-xs text-neutral-500">{{ supplier.description }}</p>
                </div>
              </VcCheckbox>
              <span v-if="supplier.isConnected" class="mt-2 pl-3">
                <VcBadge
                  variant="outline-dark"
                  color="secondary"
                  class="h-4 w-[59px] items-center text-xxs font-bold"
                  size="md"
                  rounded
                >
                  {{ $t("Buy now") }}
                </VcBadge>
              </span>
            </div>
            <VcInfinityScrollLoader
              v-if="pages > 1 && suppliers"
              :loading="loading"
              distance="400"
              class="ml-3 mr-auto mt-4"
              :is-page-limit-reached="pages === page"
              @visible="loadMore"
            />
          </div>
        </div>
      </div>
    </div>
    <template #actions="{ close }">
      <VcButton variant="outline" color="primary" @click="resetFilters(), close()">
        {{ $t("shared.checkout.payment_method_modal.cancel_button") }}
      </VcButton>

      <VcButton
        class="group ms-auto"
        @click="
          confirm();
          resetFilters();
        "
      >
        <div class="flex flex-row">
          <span>{{ $t("Select") }}</span>
          <div
            class="ml-2 flex size-5 items-center justify-center rounded-full bg-additional-50 text-center align-middle text-primary"
          >
            <span class="mt-1.5 size-5 text-xs font-bold group-hover:text-[color:var(--color-primary-hover)]">{{
              currentSelectedSuppliers.length
            }}</span>
          </div>
        </div>
      </VcButton>
    </template>
  </VcModal>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { computed, inject, onMounted, ref, shallowRef, watch } from "vue";
import { SUPPLIER_SEARCH_SORTING_LIST } from "@/core/constants";
import { QueryParamName } from "@/core/enums";
import { configInjectionKey } from "@/core/injection-keys";
// eslint-disable-next-line import/no-cycle
import { OpSupplierSearchBar } from "../components";
import { opUseSupplierSearch } from "../composables";
import type { GetSearchResultsParamsType } from "@/core/api/graphql";
import type { QuerySuppliersArgs, SupplierType } from "@/core/api/graphql/types";

const emit = defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  selectedSuppliers: () => [],
});

const config = inject(configInjectionKey);
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");

const {
  searchCriteria,
  applyFilters,
  searchSuppliersWithFacets,
  suppliers,
  categories,
  loading,
  page,
  pages,
  sortSuppliersBy,
  loadMoreSuppliers,
  resetFilters,
} = opUseSupplierSearch({ withFacets: true });

interface IEmits {
  (event: "result", updatedSuppliers: SupplierType[]): void;
}

interface IProps {
  notEditableItems?: SupplierType[] | string[];
  selectLabel?: string;
  selectedSuppliers?: Array<SupplierType>;
}

const currentSelectedSuppliers = shallowRef<SupplierType[]>([]);
const currentSelectedCategory = shallowRef<string[]>(["__any"]);

const displayedCategories = computed(() => {
  if (categories.value.length === 0) {
    return [];
  }

  const allCategory = categories.value.find((c) => c.term === "__any") || { label: "All", term: "__any", count: 0 };
  const otherCategories = categories.value
    .filter((c) => c.term !== "__any")
    .sort((a, b) => a.label.localeCompare(b.label));

  return [allCategory, ...otherCategories];
});

const onSupplierSelect = (supplier: SupplierType) => {
  if (isSupplierSelected(supplier.id)) {
    currentSelectedSuppliers.value = currentSelectedSuppliers.value.filter((s) => s.id !== supplier.id);
  } else {
    currentSelectedSuppliers.value = [...currentSelectedSuppliers.value, supplier];
  }
};

const onSortSelect = (sort: string) => {
  page.value = 1;
  sortSuppliersBy.value = sort;
  void searchSuppliersWithFacets();
};

const isSupplierSelected = (supplierId: string) => {
  return currentSelectedSuppliers?.value?.some((supplier) => supplier.id === supplierId);
};

const onCategorySelect = (category: string) => {
  page.value = 1;
  searchCriteria.value.categories = [category];

  void searchSuppliersWithFacets();
};

const isCategorySelected = (id: string) => {
  return searchCriteria.value.categories[0] === id;
};

const isNotEditableItem = (supplierId: string) => {
  return (
    props.notEditableItems?.some((item) => {
      const itemValue = typeof item === "string" ? item : item.id;
      return itemValue === supplierId;
    }) ?? false
  );
};

async function loadMore() {
  if (page.value === pages.value) {
    return;
  }

  page.value += 1;

  await loadMoreSuppliers();
}

function confirm() {
  searchCriteria.value.suppliers = currentSelectedSuppliers.value;
  applyFilters();
  emit("result", currentSelectedSuppliers.value);
}

onMounted(() => {
  void resetFilters();
  currentSelectedSuppliers.value = props.selectedSuppliers;
});
</script>
