import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import deleteTaxCertificateMutationDocument from "./opusDeleteTaxCertificate.graphql";
import type { Mutations, ResultType } from "@/core/api/graphql/types";
import type { MutationsDeleteTaxCertificateArgs } from "@/core/api/graphql/types";

export async function deleteTaxCertificate(taxCertificateId: string): Promise<ResultType> {
  const { userId } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "deleteTaxCertificate">>,
    MutationsDeleteTaxCertificateArgs
  >({
    mutation: deleteTaxCertificateMutationDocument,
    variables: {
      command: { userId, taxCertificateId },
    },
  });

  return data!.deleteTaxCertificate;
}
