<template>
  <VcModal :title="title" is-mobile-fullscreen hide-actions>
    <template #default="{ close }">
      <transition name="slide-fade-bottom" mode="out-in" appear>
        <VcAlert
          v-show="isValidAddress && errorMessage"
          color="accent"
          size="sm"
          variant="solid-light"
          class="print:hidden"
          icon
        >
          {{ errorMessage }}
        </VcAlert>
      </transition>
      <transition name="slide-fade-bottom" mode="out-in" appear>
        <VcAlert
          v-show="!isValidAddress && errorMessage"
          color="danger"
          size="sm"
          variant="solid-light"
          class="print:hidden"
          icon
        >
          {{ errorMessage }}
        </VcAlert>
      </transition>

      <VcAddressForm
        :model-value="address"
        :countries="countries"
        :states="usaRegions"
        :field-disabled="fieldDisabled"
        edit-description
        class="pt-4"
        with-description-field
        required-city
        form-content-class="mb-24 sm:m-0 px-6"
        @save="saveAddress"
      >
        <template #append="{ dirty, valid }">
          <div
            class="border-secondary-250 fixed bottom-0 flex w-full gap-4 border-t bg-additional-50 px-6 py-5 sm:relative sm:justify-end sm:py-4"
          >
            <VcButton :disabled="loading" class="flex-1 sm:flex-none" color="primary" variant="outline" @click="close">
              {{ $t("common.buttons.cancel") }}
            </VcButton>

            <VcButton :disabled="!valid || !dirty" :loading="loading" type="submit" class="flex-1 sm:flex-none">
              {{ $t("common.buttons.save") }}
            </VcButton>
          </div>
        </template>
      </VcAddressForm>
    </template>
  </VcModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { validateAddress } from "@/core/api/graphql/account";
import { useCountries } from "@/core/composables";
import { useUserAddresses } from "@/shared/account";
import type { OpusMemberAddressType } from "@/core/api/graphql/types";
import { Loading } from "@/ui-kit/components/molecules/button/vc-button.stories";
import { omit } from "lodash";

export interface IEmits {
  (event: "result", address: OpusMemberAddressType): void;
}

export interface IProps {
  address?: OpusMemberAddressType;
  loading?: boolean;
  enableAddressValidation?: boolean;
  editDescriptionOnly?: boolean;
  new?: boolean;
}

const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();

const isValidAddress = ref(false);
const errorMessage = ref("");

const { countries, usaRegions, loadCountries } = useCountries();
const { t } = useI18n();

const title = computed<string>(() => (props.new ? t("common.titles.new_address") : t("common.titles.edit_address")));

const { replaceAvalaraErrorsWithOpusErrors } = useUserAddresses();

const fieldDisabled = (fieldName: string) => {
  let result = props.loading;
  if (!result && props.editDescriptionOnly) {
    result = fieldName != "description";
  }
  return result;
};

async function saveAddress(address: OpusMemberAddressType) {
  if (props.enableAddressValidation) {
    address.key = address.id;
    const validationResult = await validateAddress(omit(address, "isActive", "isFavorite") as OpusMemberAddressType);

    if (validationResult.addressIsValid) {
      emit("result", address);
    } else {
      isValidAddress.value = false;
      if (validationResult.messages?.length) {
        errorMessage.value = replaceAvalaraErrorsWithOpusErrors(validationResult.messages[0]);
      }
    }
  } else {
    emit("result", address);
  }
}

onMounted(async () => {
  if (!countries.value.length) {
    await loadCountries();
  }
});
</script>
