import { canUseDOM } from "@apollo/client/utilities";
import { useScriptTag } from "@vueuse/core";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { Logger } from "@/core/utilities";

const DEBUG_PREFIX = "[LinkedIn Insight Tag]";
const MODULE_ID = "Opus";
const SETTINGS_MAPPING = {
  "Opus.Analytics.LinkedInInsightTagEnabled": "linkedInTagEnabled",
  "Opus.Analytics.LinkedInInsightPartnerId": "partnerId",
};

const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { linkedInTagEnabled: boolean; partnerId: string };

export function useLinkedIn() {
  function init() {
    if (!canUseDOM) {
      return;
    }

    const { linkedInTagEnabled, partnerId } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;

    if (linkedInTagEnabled && partnerId) {
      const scriptSrc = "https://snap.licdn.com/li.lms-analytics/insight.min.js";

      const { load } = useScriptTag(scriptSrc, undefined, {
        async: true,
        attrs: { id: "linkedin_insight_tag" },
      });

      const initScript = document.createElement("script");
      initScript.type = "text/javascript";
      initScript.text = `
        _linkedin_partner_id = "${partnerId}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `;
      document.head.appendChild(initScript);

      load().catch((err) => {
        Logger.error(DEBUG_PREFIX, "Failed to load LinkedIn script:", err);
      });
    }
  }

  return {
    init,
  };
}
