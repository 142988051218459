import _ from "lodash";
import { computed, readonly, ref } from "vue";
import { getAgenciesSuppliers, getSupplierAgencies, requestSetup } from "@/core/api/graphql";
import { addTaxCertificates, deleteSupplierTaxCertificate } from "@/core/api/graphql/files";
import { DEFAULT_PAGE_SIZE } from "@/core/constants";
import { SortDirection } from "@/core/enums";
import { getSortingExpression, Logger } from "@/core/utilities";
import { convertToExtendedSupplierAgency } from "../utils";
import type {
  InputRequestSetupCommand,
  InputTaxCertificateUploadRequestType,
  SupplierAgencyType,
  SupplierType,
} from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";
import type { ExtendedSupplierAgencyType } from "@/shared/company";

const agenciesSuppliers = ref<SupplierType[]>([]);

export function useSupplierAgency() {
  const loading = ref(false);
  const itemsPerPage = ref(DEFAULT_PAGE_SIZE);
  const pages = ref(0);
  const page = ref(1);
  const keyword = ref("");
  const filter = ref("");
  const supplierAgencies = ref<ExtendedSupplierAgencyType[]>([]);
  const sort = ref<ISortInfo>({
    column: "supplier.name",
    direction: SortDirection.Ascending,
  });

  async function fetchSupplierAgencies(fetchSuppliers?: boolean) {
    loading.value = true;

    const sortingExpression: string = getSortingExpression(sort.value);
    const filterExpression: string = [keyword.value, filter.value].filter(Boolean).join(" ");

    try {
      const response = await getSupplierAgencies({
        first: itemsPerPage.value,
        after: String((page.value - 1) * itemsPerPage.value),
        sort: "supplier.isConnected:desc;supplier.rank:asc;supplier.name:asc;",
        query: filterExpression,
      });
      supplierAgencies.value = _.map(response.items, (item: SupplierAgencyType) =>
        convertToExtendedSupplierAgency(item),
      );
      pages.value = Math.ceil((response.totalCount ?? 0) / itemsPerPage.value);

      if (fetchSuppliers && !agenciesSuppliers.value.length) {
        await fetchAgenciesSuppliers();
      }
    } catch (e) {
      Logger.error(`${useSupplierAgency.name}.${fetchSupplierAgencies.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchAgenciesSuppliers() {
    loading.value = true;

    try {
      agenciesSuppliers.value = await getAgenciesSuppliers({
        first: 100,
        isComingSoon: false,
      });
    } catch (e) {
      Logger.error(`${useSupplierAgency.name}.${fetchAgenciesSuppliers.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function setSupplierTaxCertificates(certificates: InputTaxCertificateUploadRequestType[]) {
    loading.value = true;

    try {
      return await addTaxCertificates(certificates);
    } catch (e) {
      Logger.error(`${useSupplierAgency.name}.${setSupplierTaxCertificates.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function removeSupplierTaxCertificate(
    taxCertificateId: string,
    supplierTaxCertificateId: string,
    withReload = true,
  ) {
    loading.value = true;

    try {
      const response = await deleteSupplierTaxCertificate(taxCertificateId, supplierTaxCertificateId);

      if (withReload) {
        await fetchSupplierAgencies();
      }

      return response;
    } catch (e) {
      Logger.error(`${useSupplierAgency.name}.${removeSupplierTaxCertificate.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function sendRequestSetup(request: InputRequestSetupCommand) {
    loading.value = true;

    try {
      return await requestSetup(request);
    } catch (e) {
      Logger.error(`${useSupplierAgency.name}.${sendRequestSetup.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    sort,
    itemsPerPage,
    page,
    keyword,
    filter,
    pages: readonly(pages),
    loading: readonly(loading),
    supplierAgencies: computed(() => supplierAgencies.value),
    agenciesSuppliers: computed(() => agenciesSuppliers.value),
    fetchSupplierAgencies,
    fetchAgenciesSuppliers,
    setSupplierTaxCertificates,
    removeSupplierTaxCertificate,
    sendRequestSetup,
  };
}
