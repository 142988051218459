<template>
  <div class="op-faq justify-center">
    <div class="z-10 flex flex-col">
      <div
        :class="{
          'bg-[url(/static/images/opus/faq/top-header-mobile.png)]': isMobile,
          'bg-[url(/static/images/opus/faq/top-header.png)]': !isMobile,
        }"
        class="z-10 flex justify-center bg-cover bg-no-repeat"
      >
        <div class="mx-auto mb-10 ml-10 mr-0 mt-6 flex w-full max-w-[1300px] flex-col md:mb-14 md:mt-11">
          <h1 class="text-2xl font-light text-additional-50 md:text-4xl">
            {{ $t("pages.faq.header") }}
          </h1>
          <div
            class="mt-6 flex max-w-60 items-center border-l-4 border-[--color-secondary-200] pl-6 text-28 font-bold text-additional-50 md:mt-5 md:h-[80px] md:max-w-full md:pl-10 md:text-5xl"
          >
            <span>{{ $t("pages.faq.subheader") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <div class="mx-auto flex w-full max-w-[1300px] flex-col scroll-smooth">
        <div
          id="stickyBlock"
          class="sticky top-[54px] z-[19] flex h-16 w-full flex-row items-center gap-4 overflow-auto whitespace-nowrap text-nowrap bg-additional-50 px-5 sm:gap-10 md:h-24 lg:top-[66px] lg:mb-2 xl:p-0"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
          <div
            v-for="(menuItem, index) in menuItems"
            :key="index"
            v-html-safe="menuItem.text"
            class="op-faq__menu__content cursor-pointer font-bold text-primary md:text-[23px]"
            @click="scrollTo(menuItem.id)"
          ></div>
        </div>
        <div class="z-10 mb-16 flex flex-col gap-7 md:mb-48">
          <div v-for="item in faqContent" :key="item.id">
            <h2
              :id="item.title"
              class="w-full bg-primary py-[6px] pl-8 text-[22px] font-bold text-additional-50 md:rounded-t"
            >
              {{ item.title }}
            </h2>
            <div
              v-for="text in item.texts"
              :key="text.id"
              :class="{ 'bg-[--color-info-50]': showAnswer[text.id], 'bg-additional-50': !showAnswer[text.id] }"
              class="flex flex-col border border-t-0 border-neutral-100 px-8 py-6 shadow-md last:border-none md:last:rounded-b"
            >
              <div class="flex flex-row items-center justify-start gap-5">
                <div
                  v-html-safe="text.content"
                  class="text-18 font-bold text-[color:#003B57] xs:w-full"
                  :class="{ 'op-faq__information__text_active': showAnswer[text.id] }"
                />
                <button
                  type="button"
                  class="op-faq__information__button ml-auto flex h-[36px] min-w-[36px] items-center justify-center self-end"
                  :class="{ 'op-faq__information__button_active': showAnswer[text.id] }"
                  @click="showAnswer[text.id] = !showAnswer[text.id]"
                >
                  <img
                    :class="{ 'rotate-180': showAnswer[text.id] }"
                    src="/static/images/opus/faq/down.svg"
                    alt="chevron"
                  />
                </button>
              </div>
              <div
                v-if="showAnswer[text.id]"
                class="mt-6 flex flex-col gap-2 transition duration-300 ease-in-out md:flex-row md:gap-5"
              >
                <div v-html-safe="text.answer" class="flex-1 text-17" />
                <!-- <div class="flex">
                  <button
                    v-if="showAnswer[text.id]"
                    type="button"
                    class="op-faq__information__button ml-auto flex h-[36px] min-w-[36px] items-center justify-center self-end"
                    :class="{ 'op-faq__information__button_active': showAnswer[text.id] }"
                    @click="showAnswer[text.id] = !showAnswer[text.id]"
                  >
                    <img
                      :class="{ 'rotate-180': showAnswer[text.id] }"
                      src="/static/images/opus/faq/down.svg"
                      alt="chevron"
                    />
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <img
          v-if="false"
          class="my-10 bg-cover bg-no-repeat md:mb-20"
          loading="lazy"
          src="/static/images/opus/faq/faq-banner_v2.webp"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePageHead } from "@/core/composables";

const { t } = useI18n();

usePageHead({
  title: t("pages.faq.meta.title"),
});

const showAnswer = ref([false]);
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");

const scrollTo = function (id: string) {
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element!.getBoundingClientRect().top;
    const currentScrollPosition = window.pageYOffset;
    const offset = elementPosition + currentScrollPosition;
    window.scrollTo({ top: offset - 160, behavior: "smooth" });
  }
};

const menuItems = [
  { id: "General", text: t("pages.faq.navigation_5") },
  {
    id: "Browse & Research",
    text: t("pages.faq.navigation_1"),
  },
  { id: "Orders", text: t("pages.faq.navigation_2") },
  { id: "Account", text: t("pages.faq.navigation_3") },
  { id: "Checkout", text: t("pages.faq.navigation_4") },
];

const faqContent = [
  {
    id: 1,
    title: "General",
    texts: [
      {
        id: 2,
        content: "What is OMNIA Partners OPUS?",
        answer:
          "OPUS is the only free ecommerce platform that provides public sector and non-profit agencies access to hundreds of competitively solicited, publicly awarded cooperative contracts across all major categories. <br /><br />Unlike traditional purchasing, OPUS allows you to research and buy from multiple suppliers with a single login and a single shopping cart, making it easier than ever to purchase the items you need. <br /><br />With no complicated setup, you can immediately access OPUS to simplify your purchasing process.",
      },
      {
        id: 3,
        content: "What are some of the key features of OPUS?",
        answer:
          "•	Search across multiple suppliers with real-time product availability and pricing; <br/> <br/>•	Buy from multiple suppliers using a single login and a single cart; <br/><br/> •	QuickConnect with hundreds of suppliers to reach an OMNIA Partners-knowledgeable supplier representative immediately for service solutions, product inquiries, and support; <br/><br/> •	No cost to use, no technical requirements, and no implementation time; <br/><br/> •	Flexible payment options including credit card, p-card, and account billing.",
      },
      {
        id: 4,
        content: "Is there a cost to OPUS?",
        answer:
          "OMNIA Partners OPUS has no cost to agency users. There are  no minimum or maximum usage requirements or limitations.",
      },
      {
        id: 5,
        content: "Who do I contact for help?",
        answer:
          'Please reach out to <a target="_blank" href="mailto:opus.support@omniapartners.com" class="text-primary hover:text-accent">OPUS Support</a> with questions or if you experience any issues with OMNIA Partners OPUS.',
      },
      {
        id: 6,
        content: "What is QuickConnect?",
        answer:
          "QuickConnect allows you to instantly connect with hundreds of suppliers for bulk quotes, product inquiries, product support, and more. Your QuickConnect submission is delivered to a supplier representative who is familiar with OMNIA Partners and public procurement. <br /><br />QuickConnects can be sent to multiple suppliers at once using the Suppliers section. <br /><br />Add files relevant to your QuickConnect using the Files section.",
      },
      {
        id: 7,
        content: "How do I know my purchases are compliant?",
        answer:
          "All cooperative purchasing agreements offered through OMNIA Partners have been awarded via thorough competitive solicitation by a public agency (e.g., state, city, county, public university or school district).",
      },
      {
        id: 8,
        content: "Does a contract in the portfolio meet my federal and grant funding requirements?",
        answer:
          'Federal and grant funding requirements are typically contemplated but differ depending on funding source. See our <a target="_blank" href="https://www.omniapartners.com/resources/public-sector-resources/federal-grant-funding" class="text-primary hover:text-accent">Federal & Grant Funding</a> page for more information.',
      },
    ],
  },
  {
    id: 8,
    title: "Browse & Research",
    texts: [
      {
        id: 9,
        content: "Can I see the contract price on OMNIA Partners OPUS?",
        answer:
          "Yes, to see prices while shopping, shop by a single supplier, view a product page, or add an item to your cart.",
      },
      {
        id: 10,
        content: "Are these my prices?",
        answer:
          "Prices are shown based on each supplier’s competitively solicited and publicly awarded Lead Public Agency contract. Your agency-specific price will be reflected upon order confirmation from the supplier after checkout and held in your order history.",
      },
      {
        id: 11,
        content: "How does OPUS determine product availability?",
        answer:
          "Our supplier partners provide their product availability to OMNIA Partners OPUS. <br/><br/> OPUS automatically shows product availability based on your shipping addresses.",
      },
      {
        id: 12,
        content: "Can I add products from multiple suppliers to one cart?",
        answer:
          "Yes, OMNIA Partners OPUS allows you to shop multiple suppliers at once and checkout with a simple, unified process.",
      },
      {
        id: 13,
        content: "Are services available on OMNIA Partners OPUS?",
        answer:
          "Services are available on OPUS. Reach a supplier to discuss your service needs easily by using QuickConnect. The supplier will receive your QuickConnect submission and a supplier representative familiar with OMNIA Partners will contact you directly.",
      },
      {
        id: 14,
        content: "Where can I find QuickConnect in OPUS?",
        answer: "QuickConnect can be accessed in several locations: Home page, Search results, Product detail page.",
      },
    ],
  },
  {
    id: 15,
    title: "Orders",
    texts: [
      {
        id: 16,
        content: "How will I know my order has shipped? Can I track my orders?",
        answer:
          "You will receive a communication directly from the supplier with your order confirmation and related details, including tracking information, if applicable. Order information is also available on the Orders page.",
      },
      {
        id: 17,
        content: "How do I cancel or edit my order?",
        answer:
          "Once an order has been placed, it cannot be cancelled or edited in OMNIA Partners OPUS. Please contact the supplier directly to make any edits or cancel an order.",
      },
    ],
  },
  {
    id: 18,
    title: "Account",
    texts: [
      {
        id: 19,
        content: "We have multiple purchasing agents. Can we share one account?",
        answer:
          "Each user must have their own account. All agency users are automatically linked to their agency. <br /><br />Admins can invite additional users to join their account by navigating to Account, then to the Users page.",
      },
      {
        id: 20,
        content: "How do I manage my supplier accounts within OPUS?",
        answer:
          "Manage suppliers by navigating to Account, then to the Manage suppliers page. <br /><br />Each supplier status is listed along with the associated payment methods, account numbers, lead agency and contract info, and tax certificate information. <br /><br />If a supplier is available for setup, click the Request setup button in the Request setup column.",
      },
      {
        id: 21,
        content: "How do I setup admin functionality?",
        answer:
          "Reach out to OPUS Support to make a user within your account to admin. <br /><br />Admins are able to invite additional users to an account. Admins are also able to deactivate and activate existing users. <br /><br />Admins are able to manage addresses for an account.<br /><br />Admins are able to setup approval workflows for users within an account.",
      },
    ],
  },
  {
    id: 22,
    title: "Checkout",
    texts: [
      {
        id: 23,
        content: "Is an order with multiple suppliers handled as multiple credit card transactions?",
        answer:
          "Yes, each supplier suborder is processed as a separate credit card transaction. For example, if a user's cart has one item valued at $500 from three different suppliers in a cart, the total order value will be around $1500. However, if that user's credit card limit is a transactional limit of $750, all suborders will go through. If that user's credit card limit is a total limit of $750, some supplier suborders will decline.",
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.op-faq {
  &__menu__content {
    padding-bottom: 1px;
    border-bottom: 1px dotted #006290;
  }

  &__information__text_active {
    font-weight: 700;
  }

  &__information__button {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #4f94c7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 13px 8px 10px 8px;
  }

  &__information__button_active {
    border: 2px solid #006290;
    padding: 10px 8px 10px 8px;
  }

  &__faq-banner {
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;
    margin-bottom: 90px;
  }
}
</style>
