import { computed, readonly, ref, shallowRef } from "vue";
import { searchSuppliers } from "@/core/api/graphql";
import { SUPPLIER_SEARCH_SORTING_LIST } from "@/core/constants";
import { Logger } from "@/core/utilities";
import { useSearchBar } from "@/shared/layout";
import type { QCSupplierSearchFilterDataType } from "../types";
import type { QuerySuppliersArgs, SupplierType } from "@/core/api/graphql/types";
import type { Ref } from "vue";

const searchCriteria: Ref<QCSupplierSearchFilterDataType> = ref({
  categories: ["__any"],
  suppliers: [],
  query: "",
});

const appliedSupplierFilters: Ref<QCSupplierSearchFilterDataType> = ref({ ...searchCriteria.value });
const loading = ref(false);
const searchPhraseOfUploadedResults = ref("");
const categories = shallowRef<{ label: string; term: string; count: number }[]>([]);
const total = ref(0);

// OPUS
const suppliers = shallowRef<SupplierType[]>([]);
const suppliersTotal = ref(0);

export function opUseSupplierSearch(options: { /** @default false */ withFacets?: boolean } = {}) {
  const itemsPerPage: Ref<number> = ref(48);
  const pages: Ref<number> = ref(0);
  const page: Ref<number> = ref(1);
  const sortSuppliersBy: Ref<string> = ref(SUPPLIER_SEARCH_SORTING_LIST[0].id);

  const isHistoryFilterEmpty = computed(() => {
    return (
      !appliedSupplierFilters.value?.categories?.length &&
      !appliedSupplierFilters.value.suppliers?.length &&
      !appliedSupplierFilters.value.query.length
    );
  });

  const isFilterDirty = computed(() => {
    const historyDataString = JSON.stringify(searchCriteria.value);
    const appliedDataString = JSON.stringify(appliedSupplierFilters.value);

    return historyDataString !== appliedDataString;
  });

  function applyFilters() {
    if (JSON.stringify(appliedSupplierFilters.value) === JSON.stringify(searchCriteria.value)) {
      return;
    }
    appliedSupplierFilters.value = { ...searchCriteria.value };
  }

  function resetFilters() {
    searchCriteria.value = { categories: ["__any"], suppliers: [], query: "" };
    sortSuppliersBy.value = SUPPLIER_SEARCH_SORTING_LIST[0].id;
    page.value = 1;
    searchSuppliersWithFacets();
  }

  function resetDataToApplied() {
    searchCriteria.value = { ...appliedSupplierFilters.value };
  }

  async function searchSuppliersWithFacets() {
    loading.value = true;
    const category = searchCriteria.value.categories[0] != "" ? searchCriteria.value.categories : [];
    const preparedParams: QuerySuppliersArgs = {
      withFacets: options.withFacets,
      query: searchCriteria.value.query,
      categories: category,
      first: itemsPerPage.value,
      sort: sortSuppliersBy.value,
    };

    try {
      suppliers.value = [];
      const { items = [], totalCount = 0, term_facets = [] } = await searchSuppliers(preparedParams);

      suppliers.value = items;
      suppliersTotal.value = totalCount;
      pages.value = Math.ceil((totalCount ?? 0) / itemsPerPage.value);
      const categoryFacet = term_facets.find((facet) => facet.name === "categories");
      if (categoryFacet?.terms) {
        categories.value = categoryFacet.terms.map((term) => ({
          label: term.label === "__any" ? "All" : term.label,
          term: term.term,
          count: term.count,
        }));
      }
    } catch (e) {
      Logger.error(`${useSearchBar.name}.${searchSuppliersWithFacets.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function loadMoreSuppliers() {
    loading.value = true;

    const category = searchCriteria.value.categories[0] != "" ? searchCriteria.value.categories : [];
    const searchParams: QuerySuppliersArgs = {
      first: itemsPerPage.value,
      after: String((page.value - 1) * itemsPerPage.value),
      withFacets: options.withFacets,
      sort: sortSuppliersBy.value,
      query: searchCriteria.value.query,
      categories: category,
    };

    try {
      const { items = [], totalCount = 0 } = await searchSuppliers(searchParams);

      suppliers.value = suppliers.value.concat(items);
      suppliersTotal.value = totalCount;
      pages.value = Math.ceil((totalCount ?? 0) / itemsPerPage.value);
    } catch (e) {
      Logger.error(`${useSearchBar.name}.${loadMoreSuppliers.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    searchCriteria,
    appliedData: computed(() => appliedSupplierFilters.value),
    isHistoryFilterEmpty,
    isFilterDirty,
    applyFilters,
    resetDataToApplied,
    searchSuppliersWithFacets,
    loadMoreSuppliers,
    sortSuppliersBy,
    page,
    resetFilters,
    pages,
    itemsPerPage,
    searchPhraseOfUploadedResults: computed(() => searchPhraseOfUploadedResults.value),
    suppliersTotal: computed(() => suppliersTotal.value),
    total: readonly(total),
    loading: readonly(loading),
    categories: computed(() => categories.value),
    suppliers: computed(() => suppliers.value),
  };
}
