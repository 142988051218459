<template>
  <VcModal
    :title="$t('shared.catalog.supplier_request_modal.title')"
    divide-actions-panel
    modal-width="max-w-[674px]"
    is-mobile-fullscreen
    is-scrollable-on-desktop
  >
    <div class="p-2 md:p-5">
      <div class="border-secondary-250 -mx-2 mb-3 border-b md:-mx-5 md:mb-4 lg:mb-5">
        <div class="flex flex-col px-6 pb-2 xs:flex-row md:pb-3.5">
          <VcImage
            class="mr-7 size-[141px] md:size-[121px] lg:size-[141px]"
            :src="supplier?.logo"
            :alt="supplier?.name + ' logotype'"
            lazy
          />
          <div class="flex flex-col gap-2 overflow-hidden">
            <VcVendor :vendor="{ name: supplier.name, id: supplier.id }" class="mt-4 xs:m-0" />
            <div v-if="supplier.description" class="text-14">
              {{ supplier.description }}
            </div>
            <a
              class="cursor-pointer text-14 font-medium text-[--link-color]"
              :href="supplier.externalLink"
              target="_blank"
            >
              {{ supplier.externalLinkDescription || supplier.externalLink }}
            </a>
          </div>
        </div>
      </div>

      <h1 class="text-21 font-bold">
        {{ $t("shared.catalog.supplier_request_modal.subtitle") }}
      </h1>
      <div class="max-h-[24px] grow"></div>
      <form class="mt-3.5" @submit="onSubmit">
        <div class="flex flex-wrap justify-between">
          <VcInput
            v-model="firstName"
            class="mb-6 w-full md:max-w-[300px]"
            :label="$t('shared.catalog.supplier_request_modal.first_name')"
            :placeholder="$t('pages.sign_up.first_name_placeholder')"
            disabled
            :message="errors.firstName"
            :maxlength="64"
            name="firstName"
            autocomplete="given-name"
          />

          <VcInput
            v-model="lastName"
            class="mb-6 w-full md:max-w-[300px]"
            :label="$t('shared.catalog.supplier_request_modal.last_name')"
            :placeholder="$t('pages.sign_up.last_name_placeholder')"
            disabled
            :message="errors.lastName ?? ''"
            :maxlength="64"
            name="lastName"
            autocomplete="family-name"
          />

          <VcInput
            v-model="email"
            class="mb-6 w-full md:max-w-[300px]"
            :label="$t('shared.catalog.supplier_request_modal.email')"
            :placeholder="$t('pages.sign_up.email_placeholder')"
            disabled
            :message="errors.email ?? ''"
            :maxlength="64"
            name="email"
            autocomplete="email"
          />

          <VcInput
            v-model="phone"
            class="mb-6 w-full md:max-w-[300px]"
            :label="$t('shared.catalog.supplier_request_modal.phone')"
            :placeholder="$t('shared.catalog.supplier_request_modal.phone_placeholder')"
            :message="errors.phone"
            :maxlength="64"
            name="phone"
            autocomplete="phone"
          />
        </div>

        <VcLabel required for="purposes" :error="!!errors.purpose">
          {{ $t("shared.catalog.supplier_request_modal.purpose") }}
        </VcLabel>
        <div v-if="purposes.length" id="purposes" class="mb-6 mt-3">
          <div class="flex flex-wrap gap-y-3 md:flex-row md:gap-8">
            <VcRadioButton
              v-for="item in purposes"
              :id="item"
              :key="item"
              v-model="purpose"
              class="w-1/2 md:w-auto"
              :value="item"
              :label="item"
            >
              <span>
                {{ item }}
              </span>
            </VcRadioButton>
          </div>
        </div>

        <div v-if="sku" class="mb-5 flex flex-col">
          <VcLabel>
            {{ $t("shared.bulk_order.manually.product_sku_label") }}
          </VcLabel>
          <div class="flex flex-col items-center gap-2 lg:flex-row lg:items-center lg:gap-5">
            <VcInput class="w-full flex-1" :model-value="sku" readonly disabled />
            <VcAlert class="h-fit w-full flex-1" color="info" size="sm" variant="solid-light" icon>
              {{ $t("common.messages.supplier_connect_sku_info") }}
            </VcAlert>
          </div>
        </div>

        <VcTextarea
          v-model="notes"
          :placeholder="$t('shared.catalog.supplier_request_modal.notes_placeholder')"
          :label="$t('shared.catalog.supplier_request_modal.notes')"
          :disabled="loading"
          :error="!!errors.notes"
          :message="errors.notes"
          class="block w-full max-w-[625px]"
          :max-length="1000"
          rows="4"
        />
      </form>
    </div>
    <template #actions="{ close }">
      <div class="flex space-x-3" :class="[isMobile ? 'w-1/2 grow' : 'w-full justify-between']">
        <VcButton color="secondary" class="w-1/2 grow md:w-auto md:grow-0 md:px-5" variant="outline" @click="close">
          {{ $t("common.buttons.cancel") }}
        </VcButton>

        <VcButton class="min-w-[145px] px-2 lg:mr-auto" :class="[isMobile && 'w-1/2 grow']" @click="onSubmit()">
          {{ $t("common.buttons.submit") }}
        </VcButton>
      </div>
    </template>
  </VcModal>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useField, useForm } from "vee-validate";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { object, string } from "yup";
import { useGoogleAnalytics } from "@/core/composables";
import { useUser } from "@/shared/account";
import type { SupplierType } from "@/core/api/graphql/types";
import { opUseQuickConnect } from "@/shared/opus/quick-connect";

interface IEmits {
  (event: "result"): void;
}

interface IProps {
  supplier: SupplierType;
  sku?: string;
}

const emit = defineEmits<IEmits>();
const props = defineProps<IProps>();

const { t } = useI18n();
const ga = useGoogleAnalytics();

const { loading, user } = useUser();
const breakpoints = useBreakpoints(breakpointsTailwind);

const { purposes, sendRequest } = opUseQuickConnect();

const isMobile = breakpoints.smaller("md");

const schema = toTypedSchema(
  object({
    email: string()
      .label("Email")
      .required()
      .email()
      .max(64)
      .test("is-unique-email", t("common.messages.email_not_unique"), () => new Promise((resolve) => resolve(true))),
    firstName: string().label("First Name").required().max(64),
    lastName: string().label("Last Name").required().max(64),
    phone: string().label("Phone").max(64).nullable(),
    purpose: string().label("Purpose").required(),
    notes: string().label("Notes").max(1000),
  }),
);

const { errors, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    email: user.value.email,
    firstName: user.value.contact?.firstName,
    lastName: user.value.contact?.lastName,
    phone: user.value.phoneNumber,
    purpose: "",
    notes: "",
  },
  validateOnMount: false,
});

const { value: firstName } = useField<string>("firstName");
const { value: lastName } = useField<string>("lastName");
const { value: email } = useField<string>("email");
const { value: phone } = useField<string>("phone");
const { value: purpose } = useField<string>("purpose");
const { value: notes } = useField<string>("notes");

const onSubmit = handleSubmit(() => {
  void sendRequest({
    supplierIds: [props.supplier.id],
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phone: phone.value,
    purpose: purpose.value,
    comment: notes.value,
  });
  emit("result");
});

onMounted(() => {
  ga.supplierQuickConnectFormOpen(props.supplier.id);
});
</script>
