import { cloneDeep } from "lodash";
import { computed, readonly, ref, shallowRef } from "vue";
import { getSuppliers, searchSuppliers, searchSuppliersLogo } from "@/core/api/graphql";
import { useOpus } from "@/core/composables";
import { SortDirection } from "@/core/enums";
import { Logger, getFilterExpressionFromFacets } from "@/core/utilities";
import type { QuerySuppliersArgs, SupplierType } from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";
import type { Ref } from "vue";

const DEFAULT_ITEMS_PER_PAGE = 24;
const loading = ref(false);
const suppliers = shallowRef<SupplierType[]>([]);
const availableSuppliers = shallowRef<SupplierType[]>([]);
const allSuppliers = shallowRef<SupplierType[]>([]);
const searchedSuppliers = shallowRef<SupplierType[]>([]);
const sort: Ref<ISortInfo> = ref({
  column: "name",
  direction: SortDirection.Descending,
});

export function useSuppliers() {
  const { buyNowCatalogFacets } = useOpus();

  const loadingMore = ref(false);
  const total = ref(0);
  const suppliersLogos: Ref<string[]> = ref([]);
  const itemsPerPage: Ref<number> = ref(DEFAULT_ITEMS_PER_PAGE);
  const pages: Ref<number> = ref(0);
  const page: Ref<number> = ref(1);

  async function fetchSuppliers(searchParams?: Partial<QuerySuppliersArgs>) {
    searchParams = {
      ...searchParams,
      first: searchParams?.first || itemsPerPage.value,
      categories: searchParams?.categories || [],
    };

    const searchCriteria = cloneDeep(searchParams);

    if (searchCriteria?.isConnected === false || searchCriteria?.isConnected === undefined) {
      delete searchParams.isConnected;
    }

    try {
      loading.value = true;

      const { items = [], totalCount = 0 } = await searchSuppliers(searchParams);

      suppliers.value = items;
      total.value = totalCount;
      pages.value = Math.ceil((totalCount ?? 0) / itemsPerPage.value);
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${fetchSuppliers.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchMoreSuppliers(searchParams: Partial<QuerySuppliersArgs>) {
    loadingMore.value = true;
    searchParams = {
      ...searchParams,
      first: itemsPerPage.value,
      after: String((page.value - 1) * itemsPerPage.value),
    };

    const searchCriteria = { ...searchParams };

    if (searchCriteria?.isConnected === false) {
      delete searchParams.isConnected;
    }

    try {
      const { items = [], totalCount = 0 } = await searchSuppliers(searchParams);

      suppliers.value = suppliers.value.concat(items);
      total.value = totalCount;
      pages.value = Math.ceil((totalCount ?? 0) / itemsPerPage.value);
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${fetchMoreSuppliers.name}`, e);
      throw e;
    } finally {
      loadingMore.value = false;
    }
  }

  async function getSuppliersLogos() {
    let countOfSuppliers = 0;
    const logosPerInitialRequest = 20;
    try {
      if (!suppliersLogos.value.length) {
        const { items = [], totalCount = 0 } = await searchSuppliersLogo({ first: logosPerInitialRequest });
        countOfSuppliers = totalCount;
        fillSuppliersLogoArray(items);
      }
      if (countOfSuppliers > logosPerInitialRequest) {
        const response = await searchSuppliersLogo({
          after: logosPerInitialRequest.toString(),
          first: countOfSuppliers - logosPerInitialRequest,
        });
        fillSuppliersLogoArray(response.items);
      }
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${getSuppliersLogos.name}`, e);
      throw e;
    }
  }

  async function fetchSearchedSuppliers(searchCriteria: Partial<QuerySuppliersArgs>) {
    try {
      loading.value = true;
      const { items = [] } = await searchSuppliers(searchCriteria);
      searchedSuppliers.value = items;
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${fetchSearchedSuppliers.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchAvailableSuppliers() {
    try {
      const { items } = await getSuppliers({ sort: "supplier.name:asc;", isConnected: true, first: 999 });
      availableSuppliers.value = items || [];
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${fetchAvailableSuppliers.name}`, e);
      throw e;
    }
  }

  async function fetchAllSuppliers() {
    try {
      if (!allSuppliers.value.length) {
        const { items } = await getSuppliers({ sort: "supplier.name:asc;", first: 999 });
        allSuppliers.value = items || [];
      }
    } catch (e) {
      Logger.error(`${useSuppliers.name}.${fetchAllSuppliers.name}`, e);
      throw e;
    }
  }

  function fillSuppliersLogoArray(logotypes?: SupplierType[]) {
    if (logotypes) {
      logotypes.forEach((supplier: SupplierType) => {
        if (supplier.logo) {
          suppliersLogos.value.push(supplier.logo);
        }
      });
    }
  }

  function getFacetsQueryForSearchProducts(outerId: string, withSettingsFilters = true): string {
    const facets: string[] = [];
    facets.push(
      getFilterExpressionFromFacets([
        {
          paramName: "SupplierOuterId",
          label: "",
          type: "terms",
          values: [
            {
              label: "",
              value: outerId,
              selected: true,
            },
          ],
        },
      ]),
    );

    if (withSettingsFilters && buyNowCatalogFacets?.value.length) {
      buyNowCatalogFacets.value.forEach((item) =>
        facets.push(
          getFilterExpressionFromFacets([
            {
              paramName: item.key,
              label: "",
              type: "terms",
              values: [
                {
                  label: "",
                  value: item.value,
                  selected: true,
                },
              ],
            },
          ]),
        ),
      );
    }

    return facets.join(" ");
  }

  return {
    itemsPerPage,
    pages,
    page,
    suppliersLogos: computed(() => suppliersLogos.value),
    fetchAvailableSuppliers,
    getSuppliersLogos,
    fetchSuppliers,
    fetchMoreSuppliers,
    fetchSearchedSuppliers,
    fetchAllSuppliers,
    orderFiltersSuppliers: computed(() => availableSuppliers.value.sort((a, b) => a.name.localeCompare(b.name))),
    allSuppliers: computed(() => allSuppliers.value),
    suppliers: computed(() => suppliers.value.filter((item: SupplierType) => item.isActive)),
    searchedSuppliers: computed(() => searchedSuppliers.value),
    loading: readonly(loading),
    loadingMore: readonly(loadingMore),
    getFacetsQueryForSearchProducts,
  };
}
