import { graphqlClient } from "@/core/api/graphql";
import { globals } from "@/core/globals";
import queryDocument from "./opusGetTaxCertificates.graphql";
import type { Query, QueryTaxCertificatesArgs, TaxCertificateConnection } from "@/core/api/graphql/types";

export async function getTaxCertificates(payload: QueryTaxCertificatesArgs): Promise<TaxCertificateConnection> {
  const { userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "taxCertificates">>, QueryTaxCertificatesArgs>({
    query: queryDocument,
    variables: {
      userId,
      ...payload,
    },
  });

  return data.taxCertificates;
}
