import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import updateTaxCertificateMutationDocument from "./opusUpdateTaxCertificate.graphql";
import type { InputUpdateTaxCertificateType, Mutations, ResultType } from "@/core/api/graphql/types";
import type { MutationsUpdateTaxCertificateArgs } from "@/core/api/graphql/types";

export async function updateTaxCertificate(payload: InputUpdateTaxCertificateType): Promise<ResultType> {
  const { userId } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "updateTaxCertificate">>,
    MutationsUpdateTaxCertificateArgs
  >({
    mutation: updateTaxCertificateMutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.updateTaxCertificate;
}
