import { computed, ref } from "vue";
import { SUPPLIER_SORTING_LIST } from "@/core/constants";
import type { QuerySuppliersArgs } from "@/core/api/graphql/types";
import type { Ref } from "vue";

const filterData: Ref<QuerySuppliersArgs> = ref({
  isConnected: false,
  sort: SUPPLIER_SORTING_LIST[0].id,
  categories: [],
});
const appliedFilterData: Ref<QuerySuppliersArgs> = ref({ ...filterData.value });

export function useSuppliersFilters() {
  const isFilterEmpty = computed(() => {
    const { isConnected, categories } = appliedFilterData.value;
    return !isConnected && !categories?.length;
  });

  const isFilterDirty = computed(() => {
    return JSON.stringify(filterData.value) !== JSON.stringify(appliedFilterData.value);
  });

  function applyFilters() {
    if (JSON.stringify(appliedFilterData.value) === JSON.stringify(filterData.value)) {
      return;
    }

    appliedFilterData.value = { ...filterData.value };
  }

  const filterChipsItems = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const items: any[] = [];
    if (appliedFilterData.value.categories?.length) {
      for (const category of appliedFilterData.value.categories) {
        items.push({ fieldName: "category", value: category, label: category });
      }
    }

    if (appliedFilterData.value.isConnected) {
      items.push({
        fieldName: "isConnected",
        value: appliedFilterData.value.isConnected,
        label: 'Show "Buy now" suppliers',
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return items;
  });

  function resetFilters() {
    filterData.value = { isConnected: false, sort: appliedFilterData.value.sort, categories: [] };
    appliedFilterData.value = { ...filterData.value };
  }

  function resetDataToApplied() {
    filterData.value = { ...appliedFilterData.value };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function removeFilterChipsItem(item: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (item.fieldName === "isConnected") {
      appliedFilterData.value.isConnected = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (item.fieldName === "category") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      appliedFilterData.value.categories?.splice(appliedFilterData.value.categories.indexOf(item.value as string), 1);
    }

    filterData.value = { ...appliedFilterData.value };
  }

  return {
    applyFilters,
    filterData,
    isFilterEmpty,
    appliedFilterData: computed(() => appliedFilterData.value),
    isFilterDirty,
    filterChipsItems,
    resetDataToApplied,
    resetFilters,
    removeFilterChipsItem,
  };
}
