import { graphqlClient } from "../../../client";
import getSuppliersQueryDocument from "./getSuppliersQuery.graphql";
import type { Query, QuerySuppliersArgs, SupplierConnection, SupplierType } from "@/core/api/graphql/types";

export async function getSuppliers(payload: QuerySuppliersArgs): Promise<SupplierConnection> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "suppliers">>, QuerySuppliersArgs>({
    query: getSuppliersQueryDocument,
    variables: {
      ...payload,
    },
  });

  return data.suppliers;
}
