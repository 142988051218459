<template>
  <!-- OPUS -->
  <template v-if="isDemoEnvironment">
    <div class="absolute inset-x-0 top-0 z-50 w-full border-t-4 !border-accent-500"></div>
    <div
      class="shadow-b-md absolute inset-x-0 top-0 z-50 mx-auto flex h-7 w-[69px] items-center justify-center rounded-b-md bg-accent-500 pb-1 font-extrabold text-additional-50"
    >
      {{ $t("shared.layout.header.top_header.demo_label") }}
    </div>
  </template>

  <template v-if="showTempPasswordNote">
    <div
      class="absolute z-50 mx-3 mt-6 flex flex-col gap-2.5 rounded-md bg-additional-50 px-6 pb-4 pt-3.5 shadow-md md:inset-x-0 md:mx-auto md:max-w-[747px] md:flex-row md:items-center"
    >
      <div class="flex items-center gap-2.5 font-bold">
        <VcIcon class="text-accent-500" name="information-circle" size="sm" />
        <span>
          {{ $t("shared.opus.set_password_alert.title") }}
        </span>
      </div>
      <span class="text"> {{ $t("shared.opus.set_password_alert.text") }}</span>
      <VcButton size="xs" class="flex-1" @click="setPassword()">
        {{ $t("shared.opus.set_password_alert.button") }}
      </VcButton>
    </div>
  </template>
  <!-- !OPUS -->

  <component :is="layout">
    <RouterView />
  </component>

  <ModalHost />
  <NotificationsHost />
  <EnvironmentBadge />
</template>

<script setup lang="ts">
import { useHead } from "@unhead/vue";
import { computedEager } from "@vueuse/core";
import { computed, markRaw, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { setupBroadcastGlobalListeners } from "@/broadcast";
import { useUser } from "@/shared/account/composables/useUser";
import { ModalHost } from "@/shared/modal";
import { NotificationsHost, useNotifications } from "@/shared/notification";
import { useWhiteLabeling } from "./core/composables";
import { SecureLayout, useSearchBar } from "./shared/layout";
import type { Component } from "vue";
import EnvironmentBadge from "@/shared/layout/components/environmentBadge/environment-badge.vue";
import MainLayout from "@/shared/layout/components/main-layout/main-layout.vue";

const route = useRoute();
const router = useRouter();
const { hideSearchBar, hideSearchDropdown } = useSearchBar();
const { favIcons } = useWhiteLabeling();
useHead({
  link: favIcons.value?.length
    ? favIcons.value
    : [
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/static/icons/opus-favicon.svg",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/static/icons/opus-favicon.svg",
        },
        {
          rel: "manifest",
          href: "/static/manifest.json",
        },
      ],
});

const layouts: Record<NonNullable<typeof route.meta.layout>, Component> = {
  Main: markRaw(MainLayout),
  Secure: markRaw(SecureLayout),
};

const layout = computedEager(() => layouts[route.meta?.layout ?? "Main"]);

router.beforeEach((to) => {
  // Hiding the drop-down list of search results
  hideSearchDropdown();

  // Hiding the search bar on mobile devices
  if (to.name !== "Search") {
    hideSearchBar();
  }
});

onMounted(setupBroadcastGlobalListeners);

// OPUS
const notifications = useNotifications();
const { forgotPassword, user } = useUser();
const { t } = useI18n();
const showTempPasswordNote = computed(() => !user.value?.passwordExpired && !!user.value?.temporaryPassword);
const isDemoEnvironment = computedEager<boolean>(() => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.host.includes("demo");
});

// TODO: Only FOR?
async function setPassword() {
  const resetPasswordUrlPath = router.resolve({ name: "ResetPassword" }).path;

  const result = await forgotPassword({
    email: user.value.email || "",
    resetPasswordUrlPath,
  });

  if (result) {
    notifications.success({
      text: t("shared.opus.set_password_alert.note"),
      duration: 5000,
      single: true,
    });
  }
}
// !OPUS
</script>

<style lang="scss">
@import "assets/styles/main.scss";
</style>
