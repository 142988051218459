<template>
  <ProductTitledBlock
    v-if="distributorName"
    :title="model.title || $t('shared.catalog.product_details.op_distributor.title')"
    icon="truck"
  >
    <VcCollapsibleContent max-height="12.5rem">
      <div class="space-y-4">
        <VcProperty :label="distributorName" class="items-end text-base">
          <VcImage :alt="distributorName" class="h-[26px]" :src="distributorImage" />
        </VcProperty>
      </div>
    </VcCollapsibleContent>
  </ProductTitledBlock>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ProductTitledBlock } from "@/shared/catalog";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

const props = defineProps<IProps>();

const distributorName = computed(
  () => props.product.properties.find((item) => item.name === "Custom_distributorname")?.value as string,
);
const distributorImage = computed(
  () => (props.product.properties.find((item) => item.name === "Custom_distributorlog_url")?.value || "") as string,
);
</script>
