import { computed, ref } from "vue";
import { setShippingLocation } from "@/core/api/graphql/opus/account";
import { OpusPermissions } from "@/core/enums";
import { Logger } from "@/core/utilities";
import { useUser, useUserAddresses } from "@/shared/account";
import { TabsType, locationChangeEvent, useBroadcast, userReloadEvent } from "@/shared/broadcast";
import type { OpusMemberAddressType } from "@/core/api/graphql/types";

export function useOpShippingLocation() {
  const loading = ref(false);
  const { contactAddresses, fetchAddresses } = useUserAddresses();
  const { user, fetchUser, checkPermissions } = useUser();
  const broadcast = useBroadcast();

  const selectedAddress = computed<OpusMemberAddressType | undefined>(() => {
    return addresses.value.find((item) => item.id === user.value?.contact?.selectedShippingLocation);
  });

  const addresses = computed<OpusMemberAddressType[]>(() => {
    const { firstName, lastName } = user.value.contact ?? {};

    return contactAddresses.value?.shippingAddresses.map((address) => ({ ...address, firstName, lastName })) || [];
  });

  async function changeShippingLocation(addressId: string) {
    loading.value = true;

    try {
      const result = await setShippingLocation(addressId);
      if (result) {
        await Promise.all([fetchAddresses(), fetchUser({ withBroadcast: true })]);

        void broadcast.emit(locationChangeEvent, undefined, TabsType.ALL);
      }
    } catch {
      Logger.error(useOpShippingLocation.name, changeShippingLocation.name);
    }

    loading.value = false;
  }

  function init() {
    if (!contactAddresses.value) {
      void fetchAddresses();
    }
  }

  return {
    loading,
    addresses,
    selectedAddress,
    userCanManageAddresses: computed(() => checkPermissions(OpusPermissions.CanEditAddresses)),
    changeShippingLocation,
    init,
  };
}
