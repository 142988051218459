import { computed, readonly, ref, shallowRef } from "vue";
import { getCountries } from "@/core/api/graphql/common";
import { getUsaRegions } from "../api/graphql";
import { Logger } from "../utilities";
import type { CountryRegionType, CountryType } from "@/core/api/graphql/types";

// OPUS
const EXCLUDED_USA_REGIONS: CountryRegionType[] = [
  { id: "AA", name: "U.S. Armed Forces - Americas" },
  { id: "AE", name: "U.S. Armed Forces - Europe" },
  { id: "AP", name: "U.S. Armed Forces - Pacific" },
  { id: "AS", name: "American Samoa" },
  { id: "FM", name: "Federated States of Micronesia" },
  { id: "GU", name: "Guam" },
  { id: "MH", name: "Marshall Islands" },
  { id: "MP", name: "Northern Mariana Islands" },
  { id: "PR", name: "Puerto Rico" },
  { id: "PW", name: "Palau" },
  { id: "VI", name: "U.S. Virgin Islands" },
];
// !OPUS

const loading = ref(false);
const countries = shallowRef<CountryType[]>([]);

async function loadCountries() {
  loading.value = true;

  try {
    countries.value = await getCountries();
  } catch (e) {
    Logger.error(loadCountries.name, e);
  } finally {
    loading.value = false;
  }
}

export function useCountries() {
  // OPUS
  const { result: usaRegions, loading: usaRegionsLoading } = getUsaRegions();
  // !OPUS

  return {
    loadCountries,
    loading: readonly(loading),
    countries: computed(() => countries.value),
    // OPUS
    usaRegionsLoading: readonly(usaRegionsLoading),
    usaRegions: computed<CountryRegionType[] | undefined>(() =>
      usaRegions.value?.regions.filter((region) => !EXCLUDED_USA_REGIONS.some((item) => item.id === region.id)),
    ),
    // !OPUS
  };
}
