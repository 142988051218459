<template>
  <VcInput
    v-model="quantity"
    :name="name"
    :readonly="readonly"
    :disabled="disabled"
    :error="error || !!errorMessage"
    :message="errorMessage"
    :aria-label="$t('common.labels.product_quantity')"
    single-line-message
    class="vc-quantity"
    :class="additionalClasses"
    :size="size || 'sm'"
    type="number"
    center
    truncate
    test-id-input="vc-quantity-input"
    @keydown="onKeydown"
    @keyup.enter="changeQuantity"
    @input="onQuantityChanged"
    @blur="onFocusOut"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>

    <template #append>
      <slot name="append" />
    </template>
  </VcInput>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { useField } from "vee-validate";
import { computed, ref, toRefs, watchEffect } from "vue";
import { LINE_ITEM_QUANTITY_LIMIT } from "@/core/constants";
import { useQuantityValidationSchema } from "@/ui-kit/composables";

interface IEmits {
  (event: "update:modelValue", value: number): void;
  (event: "trigger", value?: number): void;
}

interface IProps {
  name?: string;
  minQuantity?: number;
  maxQuantity?: number;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  modelValue?: number;
  timeout?: number;

  // OPUS
  limitation?: number;
  additionalClasses?: string;
  size?: "sm" | "md" | "auto";
  label?: string;
  disableTimeout?: boolean;
  // !OPUS
}

const emit = defineEmits<IEmits>();
const props = withDefaults(defineProps<IProps>(), {
  timeout: 900,
});

let timeoutIdOfQuantityChange: number;

const quantity = ref<number | undefined>();
// OPUS
const tempQuantity = ref<number | undefined>(quantity.value);
// !OPUS
const { minQuantity, maxQuantity } = toRefs(props);

const { quantitySchema } = useQuantityValidationSchema({ minQuantity, maxQuantity });

const rules = computed(() => toTypedSchema(quantitySchema.value));

const { errorMessage, setValue, validate } = useField("quantity", rules);

function changeQuantity() {
  emit("trigger", quantity.value);

  clearTimeout(timeoutIdOfQuantityChange);

  if (!isQuantity(quantity.value) || quantity.value === props.modelValue) {
    return;
  }

  emit("update:modelValue", quantity.value);
}

async function onQuantityChanged(): Promise<void> {
  if (!isQuantity(quantity.value)) {
    return;
  }
  // console.log(onQuantityChanged.name, quantity.value);

  // OPUS
  if (props?.limitation && quantity.value && quantity.value > props.limitation) {
    // setValue(props.limitation);
    quantity.value = props.limitation;
  } else {
    setValue(quantity.value);
  }
  // !OPUS

  // setValue(quantity.value);

  const { valid } = await validate();

  if (!valid) {
    return;
  }

  if (props.disableTimeout) {
    changeQuantity();
  } else {
    clearTimeout(timeoutIdOfQuantityChange);
    timeoutIdOfQuantityChange = +setTimeout(changeQuantity, props.timeout);
  }
}

function onFocusOut() {
  // console.log(onFocusOut.name);
  //OPUS
  // if (props?.limitation && quantity.value && quantity.value > props.limitation) {
  //   quantity.value = props?.limitation;
  //   return;
  // }
  //!OPUS

  if (!isQuantity(quantity.value)) {
    if (!quantity.value && tempQuantity.value && props?.limitation) {
      quantity.value = tempQuantity.value;
    } else {
      quantity.value = props.modelValue;
    }
  } else {
    tempQuantity.value = quantity.value;
  }

  // if (!isQuantity(quantity.value)) {
  //   quantity.value = props.modelValue;
  // }
}

function onKeydown(e: KeyboardEvent) {
  if (e.key.toLowerCase() === "e" || e.key === "-" || e.key === "." || e.key === ",") {
    e.preventDefault();
  }
}

function isQuantity(qty: unknown): qty is number {
  const qtyAsNumber = Number(quantity.value);
  return !isNaN(qtyAsNumber) && Number(qtyAsNumber) >= 1 && Number(qtyAsNumber) <= LINE_ITEM_QUANTITY_LIMIT;
}

watchEffect(() => {
  if (props.modelValue && props?.limitation && props.modelValue > props?.limitation) {
    quantity.value = props?.limitation;
  } else {
    quantity.value = props.modelValue;
  }
  // quantity.value = props.modelValue;
});
</script>

<style lang="scss">
.vc-quantity {
  @apply flex-none;
}
</style>
