<template>
  <div
    v-if="supplier.isActive"
    :class="[
      viewMode === 'grid' ? 'group min-h-[200px] flex-col justify-center text-center' : 'flex-row gap-x-14',
      hoverIsActive ? 'shadow-t-3sm hover:shadow-lg' : '',
      !hideOutline ? 'outline outline-1 outline-offset-0 outline-[color:var(--color-product-outline)]' : '',
    ]"
    class="relative flex items-center rounded bg-additional-50 p-6"
  >
    <VcImage
      :src="supplier.logo"
      :alt="supplier.name"
      size-suffix="md"
      :class="{ 'mb-4': viewMode === 'grid', 'group-hover:opacity-0': hoverIsActive }"
      class="h-[66px] w-44 object-contain"
      lazy
    />
    <div :class="{ 'group-hover:opacity-0': hoverIsActive, 'flex-1': viewMode !== 'grid' }" class="flex-col">
      <div class="text-15 font-bold">
        {{ supplier.name }}
      </div>
      <div class="mt-1 line-clamp-3 grow text-13 text-[color:var(--color-secondary)]">
        {{ supplier.description }}
      </div>
    </div>

    <!-- Buttons-->
    <div
      v-if="hoverIsActive || viewMode === 'list'"
      :class="
        viewMode === 'grid'
          ? 'group-hover:flex-col px-11 absolute opacity-0 transition-opacity justify-center items-center duration-200 ease-in group-hover:opacity-100'
          : 'justify-end flex gap-4'
      "
    >
      <VcButton
        :class="[
          viewMode === 'grid' ? 'mb-3 w-full !px-0 relative' : 'relative w-[125px]',
          supplier.isComingSoon ? 'coming-soon-label' : !supplier.isOrderAllowed && supplier.isConnected ? '' : '',
        ]"
        class="text-14"
        :disabled="!supplier.isConnected || supplier.isComingSoon || !supplier.isOrderAllowed"
        @click="selectSupplier(supplier.outerId)"
      >
        {{ $t("common.buttons.buy_now") }}
        <span
          v-if="!supplier.isComingSoon && !supplier.isOrderAllowed && supplier.isConnected"
          :class="[viewMode === 'grid' ? 'right-7' : 'w-15 right-8']"
          class="absolute top-0 -translate-y-1/2 translate-x-1/2 cursor-pointer rounded-full border-2 border-additional-50 bg-neutral-200 px-2.5 py-0.5 text-xxs font-semibold text-neutral-400"
          @click="setupRequest(supplier)"
        >
          Request setup
        </span>
      </VcButton>
      <VcButton
        :class="viewMode === 'grid' ? 'w-full !px-0' : ''"
        class="text-14"
        :variant="supplier.isConnected ? 'outline' : 'solid'"
        hide-focus
        @click="requestToSupplier(supplier)"
      >
        {{ $t("common.buttons.contact_supplier") }}
      </VcButton>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { QueryParamName } from "@/core/enums";
import { getFilterExpressionFromFacets, Logger } from "@/core/utilities";
import { OpSupplierRequestModal } from "@/shared/catalog/components";
// eslint-disable-next-line import/no-cycle
import { useSupplierAgency } from "@/shared/company";
import { useModal } from "@/shared/modal/composables";
import { useNotifications } from "@/shared/notification";
import { useSuppliers } from "@/shared/opus/categories-and-suppliers";
import { VcImage } from "@/ui-kit/components";
import { OpRequestSetup } from "../../account";
import { opUseQuickConnect } from "../../quick-connect";
import type { InputRequestSetupCommand, SupplierType } from "@/core/api/graphql/types";

interface IProps {
  supplier: SupplierType;
  viewMode: "grid" | "list";
  hoverIsActive?: boolean;
  hideOutline?: boolean;
  isSupplierList?: boolean;
}
const props = defineProps<IProps>();
const { t } = useI18n();

const { newQuickConnectEnabled, setSupplierQuickConnectInfo } = opUseQuickConnect();
const { fetchSupplierAgencies, sendRequestSetup, supplierAgencies, keyword } = useSupplierAgency();
const notifications = useNotifications();
const router = useRouter();
const route = useRoute();
const { openModal, closeModal } = useModal();
const { getFacetsQueryForSearchProducts } = useSuppliers();
function requestToSupplier(supplier: SupplierType) {
  const quickConnectLocation = props.isSupplierList ? "Suppliers" : (route.name as string);
  setSupplierQuickConnectInfo({ location: quickConnectLocation });

  if (newQuickConnectEnabled.value) {
    // QUICK CONNECT
    void router.push({
      name: "OpQuickConnect",
      query: {
        ["supplierId"]: supplier.id,
      },
    });
  } else {
    openModal({
      component: OpSupplierRequestModal,
      props: {
        supplier: supplier,
        onResult() {
          closeModal();
        },
      },
    });
  }
}

function selectSupplier(outerId?: string) {
  if (outerId) {
    void router.push({
      name: "SearchProducts",
      query: {
        [QueryParamName.Facets]: getFacetsQueryForSearchProducts(outerId),
      },
    });
  } else {
    Logger.error(`OpSupplierTileCard.${selectSupplier.name}`);
  }
}

async function setupRequest(supplier: SupplierType) {
  keyword.value = supplier.outerId;
  await fetchSupplierAgencies();

  openModal({
    component: OpRequestSetup,
    props: {
      agency: supplierAgencies.value[0],
      async onResult(request: InputRequestSetupCommand) {
        closeModal();
        const result = await sendRequestSetup(request);
        if (result.isSuccess) {
          notifications.success({
            text: t("common.messages.request_successfully_sent"),
            duration: 5000,
            single: true,
          });
        } else {
          const errorMessage = result.errors[0];
          notifications.error({
            text: errorMessage,
            duration: 10000,
            single: true,
          });
        }
      },
    },
  });
}
</script>

<style>
.coming-soon-label:before {
  content: url("/static/images/supplier/coming-soon.svg");
  position: absolute;
  width: 73px;
  z-index: 1;
  right: -10px;
  top: -13px;
  height: 18px;
}

/* .inactive-label:before {
  content: url("/static/images/supplier/inactive.svg");
  position: absolute;
  width: 73px;
  z-index: 1;
  right: -23px;
  top: -13px;
  height: 18px;
} */
</style>
