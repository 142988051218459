import { SUPPLIER_SORTING_LIST } from "@/core/constants";
import { graphqlClient } from "../../../client";
import searchSuppliersQueryDocument from "./searchSuppliersQuery.graphql";
import type { Query, QuerySuppliersArgs, SupplierConnection } from "@/core/api/graphql/types";

export async function searchSuppliers(payload: QuerySuppliersArgs): Promise<SupplierConnection> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "suppliers">>, QuerySuppliersArgs>({
    query: searchSuppliersQueryDocument,
    variables: {
      ...payload,
      sort: payload.sort || SUPPLIER_SORTING_LIST[0].id,
    },
  });

  return data.suppliers!;
}
