import { canUseDOM } from "@apollo/client/utilities";
import { useScriptTag } from "@vueuse/core";
import { sumBy } from "lodash";
import { useCurrency } from "@/core/composables/useCurrency";
import { useThemeContext } from "@/core/composables/useThemeContext";
import { IS_DEVELOPMENT } from "@/core/constants";
import { Logger } from "@/core/utilities";
import { useUser } from "@/shared/account/composables/useUser";
import { globals } from "../globals";
import { useModuleSettings } from "./useModuleSettings";
import type {
  Breadcrumb,
  OpusCartType,
  OpusCustomerOrderType,
  LineItemType,
  OrderLineItemType,
  Product,
  VariationType,
} from "@/core/api/graphql/types";

/**
 * Custom events. The items array can not be added
 */
type CustomEventNamesType =
  | "place_order"
  | "clear_cart"
  | "SupplierQuickConnect_FormOpen"
  | "SupplierQuickConnect_FormSubmit"
  | "paid_order"
  | "ImageCarousel";
type EventParamsType = Gtag.ControlParams & Gtag.EventParams & Gtag.CustomParams;
type EventParamsExtendedType = EventParamsType & { item_list_id?: string; item_list_name?: string };

const { currentCurrency } = useCurrency();
const { modulesSettings } = useThemeContext();
//OPUS
const { user } = useUser();
//!OPUS
const DEBUG_PREFIX = "[GA]";
const MODULE_ID = "VirtoCommerce.GoogleEcommerceAnalytics";
const SETTINGS_MAPPING = {
  "GoogleAnalytics4.EnableTracking": "enableTracking",
  "GoogleAnalytics4.MeasurementId": "measurementId",
};

const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { enableTracking: boolean; measurementId: string };

function init() {
  if (!canUseDOM) {
    return;
  }

  const { enableTracking, measurementId } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;

  if (enableTracking && measurementId) {
    if (!IS_DEVELOPMENT) {
      useScriptTag(`https://www.googletagmanager.com/gtag/js?id=${measurementId}`);
    } else {
      Logger.debug(DEBUG_PREFIX, "initialized without sync with google");
    }

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // is not working with rest
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };

    window.gtag("js", new Date());
    window.gtag("config", measurementId, {
      user_id: user.value?.id || null,
      agency_id: user.value?.contact?.organizationId || null,
      debug_mode: true,
    });
    window.gtag("set", { currency: currentCurrency.value.code });
  }
}

function getCategories(breadcrumbs: Breadcrumb[] = []): Record<string, string> {
  const categories: Record<string, string> = {};

  breadcrumbs
    .filter((breadcrumb) => breadcrumb.typeName !== "CatalogProduct")
    .slice(0, 5) // first five, according to the documentation
    .forEach((breadcrumb, i) => {
      const number = i + 1;
      categories[`item_category${number > 1 ? number : ""}`] = breadcrumb.title;
    });

  return categories;
}

function productToGtagItem(item: Product | VariationType, index?: number): Gtag.Item {
  const categories: Record<string, string> = "breadcrumbs" in item ? getCategories(item.breadcrumbs) : {};

  return {
    index,
    item_id: item.code,
    item_name: item.name,
    affiliation: item.vendor?.name,
    price: item.price?.list?.amount,
    discount: item.price?.discountAmount?.amount,
    quantity: item.availabilityData?.availableQuantity,
    ...categories,
  };
}

function lineItemToGtagItem(item: LineItemType | OrderLineItemType, index?: number): Gtag.Item {
  const categories: Record<string, string> = getCategories(item.product?.breadcrumbs);

  return {
    index,
    item_id: item.sku,
    item_name: item.name,
    affiliation: item.vendor?.name || "?",
    currency: item.placedPrice.currency.code,
    discount: item.discountAmount?.amount || item.discountTotal?.amount,
    price: "price" in item ? item.price.amount : item.placedPrice.amount,
    quantity: item.quantity,
    ...categories,
  };
}

/** @deprecated use direct mapping */
function getCartEventParams(cart: OpusCartType): EventParamsType {
  return {
    currency: globals.currencyCode,
    value: cart.total.amount,
    items: cart.items.map(lineItemToGtagItem),
    items_count: cart.items.length,
  };
}

function sendEvent(eventName: Gtag.EventNames | CustomEventNamesType, eventParams?: EventParamsType): void {
  if (canUseDOM && window.gtag) {
    window.gtag("event", eventName, eventParams);
  } else {
    Logger.debug(DEBUG_PREFIX, eventName, eventParams);
  }
}

function viewItemList(items: { code: string }[] = [], params?: EventParamsExtendedType): void {
  sendEvent("view_item_list", {
    ...params,
    items_skus: items
      .map((el) => el.code)
      .join(", ")
      .trim(),
    items_count: items.length,
  });
}

function selectItem(item: Product | LineItemType, params?: EventParamsExtendedType): void {
  const gtagItem = "productId" in item ? lineItemToGtagItem(item) : productToGtagItem(item);

  sendEvent("select_item", {
    ...params,
    items: [gtagItem],
  });
}

function viewItem(item: Product, params?: EventParamsExtendedType): void {
  sendEvent("view_item", {
    ...params,
    currency: globals.currencyCode,
    value: item.price?.actual?.amount,
    items: [productToGtagItem(item)],
  });
}

function addItemToWishList(item: Product, params?: EventParamsExtendedType): void {
  sendEvent("add_to_wishlist", {
    ...params,
    currency: globals.currencyCode,
    value: item.price?.actual?.amount,
    items: [productToGtagItem(item)],
  });
}

function addItemToCart(item: Product | VariationType, quantity = 1, params?: EventParamsExtendedType): void {
  const inputItem = productToGtagItem(item);

  inputItem.quantity = quantity;

  sendEvent("add_to_cart", {
    ...params,
    currency: globals.currencyCode,
    value: item.price?.actual?.amount * quantity,
    items: [inputItem],
  });
}

function addItemsToCart(items: (Product | VariationType)[], params?: EventParamsExtendedType): void {
  const subtotal: number = sumBy(items, (item) => item?.price?.actual?.amount);
  const inputItems = items.filter((item) => item).map((item) => productToGtagItem(item));

  sendEvent("add_to_cart", {
    ...params,
    currency: globals.currencyCode,
    value: subtotal,
    items: inputItems,
    items_count: inputItems.length,
  });
}

function removeItemsFromCart(items: LineItemType[], params?: EventParamsExtendedType): void {
  const subtotal: number = sumBy(items, (item) => item.extendedPrice?.amount);
  const inputItems = items.map((item) => lineItemToGtagItem(item));

  sendEvent("remove_from_cart", {
    ...params,
    currency: globals.currencyCode,
    value: subtotal,
    items: inputItems,
    items_count: inputItems.length,
  });
}

function viewCart(cart: OpusCartType, params?: EventParamsExtendedType): void {
  const cartEventParams: EventParamsType = getCartEventParams(cart);

  sendEvent("view_cart", {
    ...params,
    ...cartEventParams,
  });
}

function clearCart(cart: OpusCartType, params?: EventParamsExtendedType): void {
  const cartEventParams: EventParamsType = getCartEventParams(cart);

  sendEvent("clear_cart", {
    ...params,
    ...cartEventParams,
  });
}

function beginCheckout(cart: OpusCartType, params?: EventParamsExtendedType): void {
  try {
    sendEvent("begin_checkout", {
      ...params,
      currency: cart.currency.code,
      value: cart.total.amount,
      items: cart.items.map(lineItemToGtagItem),
      items_count: cart.items.length,
      coupon: cart.coupons?.[0]?.code,
    });
  } catch (e) {
    Logger.error(DEBUG_PREFIX, beginCheckout.name, e);
  }
}

function addShippingInfo(cart?: OpusCartType, params?: EventParamsExtendedType, shipmentMethodOption?: string): void {
  try {
    sendEvent("add_shipping_info", {
      ...params,
      shipping_tier: shipmentMethodOption,
      currency: cart?.shippingPrice.currency.code,
      value: cart?.shippingPrice.amount,
      coupon: cart?.coupons?.[0]?.code,
      items: cart?.items.map(lineItemToGtagItem),
      items_count: cart?.items.length,
    });
  } catch (e) {
    Logger.error(DEBUG_PREFIX, addShippingInfo.name, e);
  }
}

function addPaymentInfo(
  cart?: OpusCartType,
  params?: EventParamsExtendedType,
  paymentGatewayCode?: string,
  supplierOrderQty?: number,
): void {
  try {
    sendEvent("add_payment_info", {
      ...params,
      payment_type: paymentGatewayCode,
      currency: cart?.currency?.code,
      value: cart?.total?.amount,
      coupon: cart?.coupons?.[0]?.code,
      items: cart?.items.map(lineItemToGtagItem),
      items_count: cart?.items.length,
      supplier_order_qty: supplierOrderQty,
    });
  } catch (e) {
    Logger.error(DEBUG_PREFIX, addPaymentInfo.name, e);
  }
}

function purchase(order: OpusCustomerOrderType, transactionId?: string, params?: EventParamsExtendedType): void {
  try {
    sendEvent("purchase", {
      ...params,
      currency: order.currency?.code,
      transaction_id: transactionId,
      value: order.total!.amount,
      coupon: order.coupons?.[0],
      shipping: order.shippingTotal?.amount,
      tax: order.taxTotal?.amount,
      items: order.items!.map(lineItemToGtagItem),
      items_count: order?.items?.length,
    });
  } catch (e) {
    Logger.error(DEBUG_PREFIX, purchase.name, e);
  }
}

function placeOrder(order: OpusCustomerOrderType, params?: EventParamsExtendedType): void {
  try {
    sendEvent("place_order", {
      ...params,
      currency: order.currency?.code,
      value: order.total?.amount,
      coupon: order.coupons?.[0],
      shipping: order.shippingTotal.amount,
      tax: order.taxTotal.amount,
      items_count: order.items?.length,
    });
  } catch (e) {
    Logger.error(DEBUG_PREFIX, placeOrder.name, e);
  }
}

function search(searchTerm: string, visibleItems: { code: string }[] = [], itemsCount: number = 0): void {
  sendEvent("search", {
    search_term: searchTerm,
    items_count: itemsCount,
    visible_items: visibleItems
      .map((el) => el.code)
      .join(", ")
      .trim(),
  });
}

// OPUS
function supplierQuickConnectFormOpen(supplierId: string): void {
  sendEvent("SupplierQuickConnect_FormOpen", {
    supplierId,
  });
}

function supplierQuickConnectFormSubmit(supplierIds: string[], email: string): void {
  sendEvent("SupplierQuickConnect_FormSubmit", {
    supplierIds,
    email,
  });
}

function paidOrder(order: OpusCustomerOrderType, params?: EventParamsExtendedType): void {
  sendEvent("paid_order", {
    ...params,
    currency: order.currency?.code,
    value: order.total?.amount,
    coupon: order.coupons?.[0],
    shipping: order.shippingTotal?.amount,
    tax: order.taxTotal?.amount,
    items: order.items!.map(lineItemToGtagItem),
  });
}

function imageClick(url: string, alt: string): void {
  sendEvent("ImageCarousel", {
    url,
    alt,
  });
}

function login(userId: string, agencyId: string | null): void {
  window.gtag("set", { user_id: userId });

  sendEvent("login", {
    customer_id: userId,
    agency_id: agencyId,
    sign_in_time: new Date().toISOString(),
  });
}
// !OPUS

export function useGoogleAnalytics() {
  return {
    sendEvent,
    viewItemList,
    selectItem,
    viewItem,
    addItemToWishList,
    addItemToCart,
    addItemsToCart,
    removeItemsFromCart,
    viewCart,
    clearCart,
    beginCheckout,
    addShippingInfo,
    addPaymentInfo,
    purchase,
    placeOrder,
    search,
    init,
    // OPUS
    supplierQuickConnectFormOpen,
    supplierQuickConnectFormSubmit,
    paidOrder,
    imageClick,
    login,
    // !OPUS
  };
}
