import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import getAgenciesSuppliersQuery from "./getAgenciesSuppliersQuery.graphql";
import type { SupplierType, Query, QuerySupplierAgenciesArgs } from "@/core/api/graphql/types";

export async function getAgenciesSuppliers(payload: QuerySupplierAgenciesArgs): Promise<SupplierType[]> {
  const { userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "supplierAgencies">>, QuerySupplierAgenciesArgs>({
    query: getAgenciesSuppliersQuery,
    variables: {
      userId,
      ...payload,
    },
  });

  return data.supplierAgencies.items?.map((item) => item.supplier) || [];
}
